html {
  background-color: #1976d2;
}

body {
  position: relative;
  color: white;
  height: 100%;
  font-family: sans-serif;
  overflow-y: scroll;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  background-color: #1976d2;
}

#root {
  background-color: #1976d2;
  height: 100%;
  width: 100% !important;
  margin: 0;
}

button {
  background-color: white;
  padding: 10px 20px;
  border-radius: 10px;
  border: none;
  appearance: none;
  font-size: 1.3rem;
  box-shadow:
    0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    0px 18px 88px -4px rgba(24, 39, 75, 0.14);
  transition: all ease-in 0.1s;
  cursor: pointer;
  opacity: 0.9;
}

button:hover {
  transform: scale(1.05);
  opacity: 1;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  height: fit-content;
  width: fit-content;
  margin: 10px;
}

a:hover {
  opacity: 1;
  text-decoration: none;
}

/**
ag-grid
 */
.ag-external-filters .ag-root-wrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

@media (max-width: 600px) {
  .ag-paging-page-size,
  .ag-paging-row-summary-panel {
    display: none;
  }

  .ag-paging-page-summary-panel {
    margin: 0 !important;
  }

  .ag-paging-panel {
    justify-content: center !important;
    align-items: center !important;
  }
}

/**
yoopta
*/

.yoopta-editor {
  padding-left: 50px;
  padding-bottom: 50px !important;
}

/* Numbered list */
.yoopta-numbered-list {
  @apply yoo-lists-ml-[10px] yoo-lists-flex yoo-lists-items-center yoo-lists-pl-4 yoo-lists-space-x-1 yoo-lists-py-[2px] yoo-lists-text-[16px] yoo-lists-relative yoo-lists-leading-[28px];
}

.yoopta-numbered-list-count {
  @apply yoo-lists-min-w-[10px] yoo-lists-w-auto yoo-lists-select-none yoo-lists-absolute yoo-lists-left-0 yoo-lists-top-[2px];
}

.yoopta-numbered-list-content {
  @apply yoo-lists-flex-grow;
}

/* Bulleted list */
.yoopta-bulleted-list {
  @apply yoo-lists-ml-[10px] yoo-lists-flex yoo-lists-items-center yoo-lists-pl-4 yoo-lists-space-x-1 yoo-lists-py-[2px] yoo-lists-text-[16px] yoo-lists-leading-7 yoo-lists-relative;
}

.yoopta-bulleted-list-bullet {
  @apply yoo-lists-min-w-[10px] yoo-lists-w-auto yoo-lists-select-none yoo-lists-absolute yoo-lists-left-0 yoo-lists-top-[0px] yoo-lists-font-bold;
}

.yoopta-bulleted-list-content {
  @apply yoo-lists-flex-grow yoo-lists-ml-0;
}

/* Todo list */
.yoopta-todo-list {
  @apply yoo-lists-ml-[10px] yoo-lists-flex yoo-lists-items-center yoo-lists-pl-4 yoo-lists-space-x-1 yoo-lists-py-[2px] yoo-lists-text-[16px] yoo-lists-leading-[28px] yoo-lists-relative;
}

.yoopta-todo-list-content {
  @apply yoo-lists-flex-grow;
}

.yoopta-todo-list-checkbox {
  @apply yoo-lists-select-none yoo-lists-cursor-pointer yoo-lists-absolute yoo-lists-left-0 yoo-lists-top-[4px];
}

.yoopta-todo-list-checkbox-input {
  @apply yoo-lists-min-w-[10px] yoo-lists-w-auto;
}

/* defilama icon */
.cls-1 {
  fill: url(#linear-gradient-2);
}

.cls-2 {
  fill: #fff;
}

.cls-2,
.cls-3,
.cls-4,
.cls-5 {
  fill-rule: evenodd;
}

.cls-3 {
  fill: #b3d2ff;
}

.cls-6 {
  fill: url(#linear-gradient-5);
}

.cls-4 {
  fill: #0dff81;
}

.cls-7 {
  fill: none;
}

.cls-5 {
  fill: #003580;
}

.cls-8 {
  fill: url(#linear-gradient-3);
}

.cls-9 {
  fill: url(#linear-gradient);
}

.cls-10 {
  fill: url(#linear-gradient-4);
}

.altcoin-season-index {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 20px 0;
}

.sectors-container {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.sector {
  height: 100%;
}

.pointer {
  position: absolute;
  top: 0;
  width: 10px;
  height: 100%;
  border-radius: 50%;
  transform: translateX(-50%);
}

/* CSS (GeneralProgressBar.css) */
.general-progress-bar {
  position: relative;
  width: 100%;
  height: 40px;
  margin: 20px 0;
}

.general-progress-bar__sectors-container {
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.general-progress-bar__sector {
  height: 100%;
}

.general-progress-bar__pointer {
  position: absolute;
  top: 0;
  width: 10px;
  height: 100%;
  border-radius: 50%;
  transform: translateX(-50%);
}

/**
HeatMap
 */

.tradingview-widget-container {
  position: relative;
  width: 100%;
  height: 100%;
}

/* Style for the overlay div */
.tradingview-widget-container .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10; /* Ensure the overlay is above the widget */
  background-color: transparent; /* Transparent to not obscure the widget */
  cursor: not-allowed; /* Optional: Indicate non-interactivity */
}
